import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import api from 'api/api';
import { permissions } from 'utils/userPermissions';
import { useWebsocketSubscription } from 'hooks/useWebsocketSubscription';
import logo from 'static/logo_grey.svg';
import { ReactComponent as ServicesAndFeesIcon } from 'static/servicesAndFeesIcon.svg';
import { ReactComponent as HallTvIcon } from 'static/hallTvIcon.svg';

import Time from './Time';
import Item from './Item';
import NewsTicker from './NewsTicker';

import './Header.css';
import userIcon from './Item/user.svg';

const Header = () => {
  const { username, icao, customerGroupName, permissionsList, isServiceUser } = useSelector(
    (state) => state.auth.userInfo
  );
  const isFlightsModalAllowed = permissionsList.includes(permissions.PAGE_AIRPORT_TV_SCREENS);
  const isLocalBrowserTimeShouldBeShown = permissionsList.includes(permissions.OPTION_LOCAL_BROWSER_TIME);
  const isGroupNameCouldBeShown = permissionsList.includes(permissions.OPTION_GROUP_NAME);
  const isDashboard = useRouteMatch('/dashboard');
  const [tickerText] = useWebsocketSubscription('newsTicker');
  const isTickerTextEmpty = typeof tickerText === 'string' && tickerText === '';
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <header className="header">
      <div className="header__scrollable">
        <div className="header-logo-wrapper">
          <Link to="/">
            <img src={logo} alt="Virtower" className="header-logo" />
          </Link>
        </div>
        <div className="header-main">
          {isDashboard && !isTickerTextEmpty && isVisible && (
            <Item
              name="News"
              content={<NewsTicker text={tickerText} onToggleVisibility={toggleVisibility} />}
              className="header-ticker"
            />
          )}
          <Time name="LOC Time" localBrowserTime={isLocalBrowserTimeShouldBeShown} />
          <Time name="UTC Time" utc />
          {isGroupNameCouldBeShown ? (
            <Item name="GROUP" content={customerGroupName} />
          ) : (
            <Item name="ICAO" content={icao} />
          )}
          <Item
            name="Logged in"
            content={isServiceUser ? icao : username}
            icon={userIcon}
            onClick={() => {
              api
                .logout()
                .then()
                .catch()
                .finally(() => {
                  /** For backward compatibility */
                  document.cookie =
                    'virtower_user_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' +
                    window.location.hostname +
                    '; path=/';
                  window.location.reload();
                });
            }}
          />
          {isFlightsModalAllowed && (
            <>
              <Link to="/hall" target="_blank" className="header-button header__group_delimiter">
                <HallTvIcon alt="Open hall tv screen" className="header-button__icon" />
              </Link>
              <Link to="/services" target="_blank" className="header-button">
                <ServicesAndFeesIcon className="header-button__icon" alt="Open services and fees screen" />
              </Link>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
